<template>
  <div>
    <div
      class="
        absolute
        h-screen
        w-screen
        top-0
        left-0
        flex flex-col
        justify-center
        items-center
        bg-blue-300 bg-opacity-50
      "
      style="z-index: 999999999"
      v-if="loading"
    >
      <div class="block">
        <img src="@/assets/svg/loader.svg" class="block" />
      </div>
      <div
        class="
          block
          my-6
          bg-dokBlue-ultra
          py-4
          px-10
          text-white
          rounded-lg
          w-1/4
          clearfix
        "
      >
        <span class="float-left text-xl font-bold">{{ callState }} ...</span>
        <button
          class="
            bg-danger
            border-0
            rounded-full
            text-white
            py-2
            px-3
            float-right
          "
          @click="onEndCallClicked()"
        >
          <i class="fas fa-phone-slash"></i>
        </button>
      </div>
    </div>

    <div
      class="video-fixed cursor-move"
      ref="draggableContainer"
      @mousedown="dragMouseDown"
    >
      <div id="localTrack" ref="localTrack" class="one"></div>
      <div id="remoteTrack" ref="remoteTrack" class="two"></div>
      <div
        class="
          bg-dokBlue-ultra
          py-3
          px-2
          text-white
          rounded-cu
          w-full
          flex
          items-center
          justify-center
        "
      >
        <button
          class="
            bg-blackdok
            border-0
            rounded-full
            text-white
            py-2
            px-3
            cursor-pointer
          "
          @click="collectionVideo"
        >
          <i class="fas fa-window-restore"></i> {{ callState }}
        </button>
        <button
          class="
            bg-white
            border-0
            rounded-full
            text-dokBlue-ultra
            py-2
            px-3
            cursor-pointer
            mx-2
          "
          @click="onMuteClicked()"
        >
          <i
            :class="muted ? `fas fa-microphone` : `fas fa-microphone-slash`"
          ></i>
        </button>
        <button
          class="
            bg-red-700
            border-0
            rounded-full
            text-white
            py-2
            px-3
            cursor-pointer
          "
          @click="onEndCallClicked()"
        >
          <i class="fas fa-phone-slash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { connect, createLocalVideoTrack } from "twilio-video";

export default {
  name: "VideoItem",
  props: ["conversationId", "onCloseCallClicked"], // props that will be passed to this component
  data() {
    return {
      muted: false,
      startVideoCall: false,
      loading: false,
      data: {},
      localTrack: false,
      remoteTrack: "",
      activeRoom: "",
      previewTracks: "",
      identity: "",
      roomName: null,
      accessToken: null,
      callState: "CALLING",
      localVideoTrack: null,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        move: true,
      },
    };
  },
  watch: {
    "positions.clientX": function (newVal) {
      if (newVal <= 100 || newVal >= this.getWidth()) {
        this.expandVideo();
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", this.leaveRoomIfJoined);
  },
  mounted() {
    this.remoteTrack = this.$refs.remoteTrack;
    this.localTrack = this.$refs.localTrack;
    this.callState = "CALLING";
    this.$store.dispatch("user/GET_TWILIO_TOKEN", this.onTwilioToken);

    this.$store.dispatch("chat/INIT_VIDEO", {
      id: this.conversationId,
      onData: (res) => {},
    });
  },
  methods: {
    getWidth() {
      return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
    },
    dragMouseDown(event) {
      event.preventDefault();
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event.preventDefault();

      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      if (!this.positions.move) return;

      if (this.$refs.draggableContainer) {
        this.$refs.draggableContainer.style.top =
          this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          "px";
        this.$refs.draggableContainer.style.left =
          this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          "px";
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    async initializeCall() {
      this.localVideoTrack = await createLocalVideoTrack();
      this.localTrack.appendChild(this.localVideoTrack.attach());
      connect(this.accessToken, {
        name: this.conversationId,
        audio: true,
        video: { width: 300 },
      })
        .then(this.onConnect)
        .catch(this.onFailure);
    },
    async onConnect(room) {
      this.loading = false;
      this.activeRoom = room;
      // if room has already participant
      room.participants.forEach(this.attachParticipantTracks);
      room.on("participantConnected", this.attachParticipantTracks);
      room.on("participantDisconnected", this.onEndCallClicked);
      room.on("disconnected", this.onDisconnect);
    },
    onTwilioToken(data) {
      const {
        data: { token: accessToken },
      } = data;
      this.accessToken = accessToken;
      if (this.conversationId) {
        this.initializeCall();
        // this.createChat(this.conversationId);
      }
    },
    onDisconnect() {
      this.localVideoTrack.stop();
      const mediaElements = this.localVideoTrack.detach();
      mediaElements.forEach((mediaElement) => mediaElement.remove());
    },
    onFailure(err) {
      //console.log("onFailure", err);
    },
    collectionVideo() {
      EventBus.$emit("collectionVideo");
    },
    onMuteClicked() {
      if (!this.activeRoom) return;
      this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
        if (!publication.track.isEnabled) {
          this.muted = true;
          publication.track.enable();
        } else {
          this.muted = false;
          publication.track.disable();
        }
      });
    },
    onEndCallClicked() {
      if (this.activeRoom) {
        // //console.log()
        this.activeRoom.disconnect();
      }
      this.onCloseCallClicked();
    },
    attachParticipantTracks(participant) {
      participant.tracks.forEach((publication) => {
        if (publication.track) {
          this.remoteTrack.appendChild(publication.track.attach());
        }
      });
      participant.on("trackSubscribed", (track) => {
        this.remoteTrack.appendChild(track.attach());
      });
    },
  },
};
</script>

<style lang="scss">
.video-fixed {
  z-index: 9999999;
  width: 400px;
  height: auto;
  @apply overflow-hidden;
  @apply absolute;
  @apply mr-8;
  @apply rounded-cu;
  @apply bg-blackdok;
  top: 50%;
  right: 0%;
  .two {
    height: 290px;
    overflow: hidden;
    @apply rounded-lg;
    @apply m-2;
    video {
      width: 100%;
      height: auto;
    }
  }
  .one {
    width: 140px;
    height: 140px;
    overflow: hidden;
    @apply absolute;
    @apply rounded-lg;
    @apply m-2;
    video {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 638px) {
  .video-fixed {
    top: 20%;
    width: 210px;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
    .two {
      position: static;
      width: 140px;
      height: 100px;
      overflow: hidden;
      @apply rounded-lg;
      @apply m-2;
      video {
        width: 100%;
        height: auto;
      }
    }
    .one {
      position: static;
      width: 140px;
      height: 100px;
      overflow: hidden;
      @apply rounded-lg;
      @apply m-2;
      video {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
